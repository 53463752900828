import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/seo"
import { CustomThemeProvider } from "../theme/ThemeProvider"
import styled from "styled-components"

const TermsOfService = ({ data }) => {
  const { html } = data.markdownRemark
  const { title } = data.markdownRemark.frontmatter
  return (
    <CustomThemeProvider>
      <Layout>
        <Seo title={"Terms of Service"} />
        <MainWrapper>
          <Title>{title}</Title>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </MainWrapper>
      </Layout>
    </CustomThemeProvider>
  )
}

export default TermsOfService

const MainWrapper = styled.div`
  padding: 4rem 0px 20px 0px;
  max-width: 1280px;
  margin: 0 auto;

  @media (max-width: 1334px) {
    padding-left: 10%;
    padding-right: 10%;
  }
`

const Title = styled.h1`
  text-align: center;
`

export const query = graphql`
  query TosQuery($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
